import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import { useGlobalState, useGlobalStateUpdate } from '../../GlobalStateContext';
import Studio from './Studio';
import Contact from './Contact';
import MobileMenu from './MobileMenu';
import useIsMobile from '../../Hooks/useIsMobile';
import AnimatedLogo from '../../logo/AnimatedSvgLogo';
import '../styles/layout.css';

const Layout = ({ onBlackoutChange }) => {

  const { showStudio, showContact } = useGlobalState();
  const { toggleStudio, toggleContact } = useGlobalStateUpdate();
  const [projects, setProjects] = useState([]);
  const [activeVideo, setActiveVideo] = useState('video1');
  const [isClosing, setIsClosing] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const projectId = location.state;

  useEffect(() => {

    axios.get(`${process.env.REACT_APP_API_URL}/projects`)
      .then((response) => {
        setProjects(response.data);
      })
      .catch((error) => {
        console.error('Erreur lors du fetch des projets', error);
      });
  }, []);


  const isSingleProjectPage = location.pathname.startsWith('/project')

  const handleDeleteProject = async () => {

    const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer ce projet ?');

    if (confirmDelete) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/projects/${projectId}`);
        setProjects(projects.filter(project => project._id !== projectId));
        navigate('/'); // Redirige vers la page d'accueil ou une autre page
      } catch (error) {
        console.error('Erreur lors de la suppression du projet', error);
      }
    }
  };

    // Fonction pour ouvrir la modal Studio en fixant isClosing à false
    const openStudio = () => {
      setIsClosing(false);
      toggleStudio();
    };

    // Fonction pour fermer la modal Studio avec animation
    const closeStudio = () => {
      setIsClosing(true);
    };

    // Fonction pour ouvrir la modal Studio en fixant isClosing à false
    const openContact = () => {
      setIsClosing(false);
      toggleContact();
    };

    // Fonction pour fermer la modal Contact avec animation
    const closeContact = () => {
      setIsClosing(true);
    };

    const openMenu = () => {
      if (showStudio) closeStudio();
      if (showContact) closeContact();
      setIsMenuOpen(true);
    };

    const handleBlackout = () => {
      onBlackoutChange(true);
      setTimeout(() => {
        navigate('/')
        onBlackoutChange(false)
      }, 500);
    }

  return (
    <div className={`layout ${isSingleProjectPage ? 'black-background' : ''}`}>
      {showContact && <Contact isClosing={isClosing} />}
      <div className="sidebar left">
        <div onClick={handleBlackout} className="logo">
          <AnimatedLogo />
        </div>
      </div>
      <div className="content">
        <Outlet />
      </div>
      <div className="sidebar right">
        {!isMobile ? (
          <>
            <Link className={showStudio ? "link studio-return-link" : "link studio-link"} onClick={showStudio ? closeStudio : openStudio}>{showStudio ? 'Retour' : "Studio"}</Link>
            <Link className={showContact ? "link contact-return-link" : "link contact-link"} onClick={showContact ? closeContact : openContact}>{showContact ? 'Retour' : "Contact"}</Link>
          </>
        ) : (
          <>
            <Link className="link menu-link" onClick={openMenu}>Menu</Link>
            <MobileMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} openStudio={openStudio} openContact={openContact} />
          </>
        )}
        {/* <Link to="/create-project" className="btn btn-secondary mb-3 creer">Créer un projet</Link>
        {isSingleProjectPage && (
          <>
            <Link to={`/update-project`} state={projectId} className="btn btn-secondary mb-3 modifier">Modifier le projet</Link>
            <button onClick={handleDeleteProject} className="btn btn-danger mb-3 supprimer">Supprimer le projet</button>
          </>
        )} */}
      </div>
      {showStudio && <Studio isClosing={isClosing} />}
    </div>
  );
};

export default Layout;
