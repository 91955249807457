import { useEffect, useState } from 'react';
import useIsMobile from '../../Hooks/useIsMobile';
import { useGlobalStateUpdate } from '../../GlobalStateContext';
import { useNavigate } from 'react-router-dom';
import '../styles/studio.css';

const Studio = ({ isClosing }) => {

  const { toggleStudio } = useGlobalStateUpdate();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [showVideoShowReel, setShowVideoShowReel] = useState(false);


  useEffect(() => {
    if (isClosing) {
      const timer = setTimeout(() => {
        toggleStudio();
      }, 450);
      return () => clearTimeout(timer);
    }
  }, [isClosing, toggleStudio]);

  useEffect(() => {
    addHoverListeners();
  });

  const addHoverListeners = () => {
    const interactiveElements = document.querySelectorAll('.logo-studio, .showShowreel');
    interactiveElements.forEach((element) => {
      element.addEventListener('mouseenter', () => {
        const customCursor = document.querySelector('.custom-cursor');
        if (customCursor && !element.classList.contains('video')) {
          customCursor.classList.add('hover');
        }
      });
      element.addEventListener('mouseleave', () => {
        const customCursor = document.querySelector('.custom-cursor');
        if (customCursor && !element.classList.contains('video')) {
          customCursor.classList.remove('hover');
        }
      });
    });
  };

   // Fonction pour afficher la modal vidéo
   const handleShowreelClick = () => {
    setShowVideoShowReel(true);
    document.body.classList.add('hide-custom-cursor');
  };

  // Fonction pour fermer la modal vidéo
  const handleCloseVideo = () => {
    setShowVideoShowReel(false);
    document.body.classList.remove('hide-custom-cursor');
  };

  return (
    <>
      <div className={`studio-overlay ${isClosing ? 'closing' : ''}`}>
        <div className='logo-studio' onClick={() => toggleStudio()}></div>
        {!isMobile ? (
          <div className="studio-content">
            <div className='background-image-studio'></div>
            <div className='text'>
              <h5>
                La Dall, studio monolithe Francais concentré de créativité et de technologie Spécialisé dans la création d'images et de vidéos innovantes,
                 nous nous focalisons principalement sur la production de contenus pour des événements de toute envergure.
              </h5>
              <br/>
              <div>
                <p>
                  Tom SENTUC - directeur artistique / motion designer
                </p>
                <p>
                  Johan GOUHENANT - Designer 2D/3D / motion designer
                </p>
              </div>
              <br/>
              <p>Compétences:</p>
              <p>
                Direction artistique, design 2D/3D, Motion design, Illustration,
                Design graphique, Identitée, Design interactif, mapping video
              </p>
                <h4 className='showShowreel' onClick={handleShowreelClick}>VOIR NOTRE SHOWREEL</h4>
            </div>
        </div>
        ) : (
          <div className="studio-content">
            <div className='text'>
              <p>
                La Dall, studio monolithe Francais concentré de créativité et de technologie Spécialisé dans la création d'images et de vidéos innovantes,
                nous nous focalisons principalement sur la production de contenus pour des événements de toute envergure.
              </p>
              <br/>
              <br/>
              <div className='artist'>
                <p>
                  Tom SENTUC
                </p>
                <p>
                  directeur artistique / motion designer
                </p>
                <br />
                <p>
                  Johan GOUHENANT
                </p>
                <p>
                  Designer 2D/3D / motion designer
                </p>
              </div>
              <br/>
              <br/>
              <p>Compétences:</p>
              <p>
                Direction artistique, design 2D/3D, Motion design, Illustration,
                Design graphique, Identitée, Design interactif, mapping video
              </p>
                <h1 className='showShowreel'  onClick={handleShowreelClick}>VOIR NOTRE SHOWREEL</h1>
            </div>
        </div>
        )}
      </div>
      {/* Modal pour afficher la vidéo Vimeo */}
      {showVideoShowReel && (
        <div className="showreel-video-overlay">
          <div className="showreel-video-container">
            <iframe
              src="https://player.vimeo.com/video/992899087"
              width="100%"
              height="100%"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Showreel"
            ></iframe>
            <button className="showreel-close-button" onClick={handleCloseVideo}>X</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Studio;
